import React, { useState } from "react";
import styled from "styled-components";
import Eco from "../images/eco.png";

const NavInfoLine = styled.div`
  width: 28.28px;
  height: 1px;
  background-color: black;
  transform-origin: top left;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  bottom: 20px;
  transition: opacity 0.2s ease;

  display: block;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavInfoContainer = styled.div`
  width: 350px;
  height: 250px;
  background-color: white;
  bottom: 0;
  left: 19px;
  position: absolute;
  border: 1px solid black;

  display: grid;
  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 40px;

  @media (max-width: 1000px) {
    bottom: auto;
    top: calc(100% - 1px);
    left: calc(12% + 9px);
    width: calc(100% - 12% - 9px);
    box-sizing: border-box;
    z-index: 7;
  }
`;

const NavInfoMenu = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid black;
`;

const NavInfoMenuButton = styled.button`
  width: 100%;
  height: 39px;
  box-sizing: border-box;
  padding: 0;
  border: none;
  border-right: 1px solid black;
  background: ${(props) => (props.selected ? "#0000ff" : "none")};
  outline: none;
  display: grid;
  place-items: center;
  color: ${(props) => (props.selected ? "white" : "black")};
  font-size: 14px;
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover {
    background-color: #0000ff;
    color: white;
  }
`;

const NavInfoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  overflow-y: scroll;
`;

const NavInfoContact = styled.div`
  width: calc(100% - 40px);
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const NavInfoContactLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
`;

const NavInfoContactLabel = styled.span`
  color: black;
  font-size: 14px;
  margin: 0;
`;

const NavInfoContactLink = styled.a`
  text-decoration: none;
  color: black;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;

  &:hover {
    color: #0000ff;
  }
`;

const NavInfoParagraph = styled.p`
  width: calc(100% - 40px);
  margin: 0 auto 20px auto;
  color: black;
  font-size: 14px;
`;

const NavInfoImg = styled.img`
  width: calc(100% - 60px);
  margin: 0 auto 20px auto;
`;

const NavInfo = ({ visible }) => {
  const [view, setView] = useState("contact");
  const switchContact = () => setView("contact");
  const switchAbout = () => setView("about");
  const switchReturns = () => setView("returns");

  return (
    <>
      <NavInfoLine visible={visible}></NavInfoLine>
      <NavInfoContainer visible={visible}>
        <NavInfoContent>
          {view === "contact" ? (
            <NavInfoContact>
              <NavInfoContactLine>
                <NavInfoContactLabel>EMAIL:</NavInfoContactLabel>
                <NavInfoContactLink href="mailto:contact@atypic.cc">
                  contact@atypic.cc
                </NavInfoContactLink>
              </NavInfoContactLine>
              <NavInfoContactLine>
                <NavInfoContactLabel>PRESS:</NavInfoContactLabel>
                <NavInfoContactLink href="mailto:r@rxstudio.co">
                  r@rxstudio.co
                </NavInfoContactLink>
              </NavInfoContactLine>
              <NavInfoContactLine>
                <NavInfoContactLabel>INSTAGRAM:</NavInfoContactLabel>
                <NavInfoContactLink
                  href="https://www.instagram.com/atypic___/"
                  target="_blank"
                >
                  @ATYPIC___
                </NavInfoContactLink>
              </NavInfoContactLine>
            </NavInfoContact>
          ) : (
            <>
              {view === "about" ? (
                <>
                  <NavInfoParagraph>
                    A. ECO PLAN - FOR THE ADVANCEMENT OF AN ECOSYSTEM
                  </NavInfoParagraph>
                  <NavInfoParagraph>
                    IDENTIFY AND MITIGATE THE ENVIRONMENTAL IMPACTS THAT MAY
                    RESULT FROM COMPANY ACTIVITIES.
                  </NavInfoParagraph>
                  <NavInfoParagraph>
                    WHEN PURCHASING, REFERENCE THE ATTRIBUTED LOGOS BELOW TO
                    IDENTIFY WHICH ITEMS ARE ECOLOGICALLY ETHICAL.
                  </NavInfoParagraph>
                  <NavInfoImg src={Eco} />
                  <NavInfoParagraph>
                    ONLY ORDER WHAT YOU WANT AND INTEND TO KEEP *
                  </NavInfoParagraph>
                </>
              ) : (
                <NavInfoParagraph>
                  ALL SALES ARE FINAL, NO RETURNS OR REFUNDS, UNLESS PRODUCTS IS
                  PROVEN FAULTY.
                </NavInfoParagraph>
              )}
            </>
          )}
        </NavInfoContent>
        <NavInfoMenu>
          <NavInfoMenuButton
            onClick={switchContact}
            selected={view === "contact"}
          >
            CONTACT
          </NavInfoMenuButton>
          <NavInfoMenuButton onClick={switchAbout} selected={view === "about"}>
            ECO PLAN
          </NavInfoMenuButton>
          <NavInfoMenuButton
            onClick={switchReturns}
            selected={view === "returns"}
          >
            RETURNS
          </NavInfoMenuButton>
        </NavInfoMenu>
      </NavInfoContainer>
    </>
  );
};

export default NavInfo;
