import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { gsap } from "gsap";

const StatusBarContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 9px 0 9px 0;
  /* margin-top: 15px; */
  height: 30px;
  background: none;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

const StatusBarWrapper = styled.ul`
  padding: 0px;
  margin: 0px;
  position: absolute;
  li {
    display: inline;
    font-size: 16px;
    font-family: "Helvetica Neue Light", Sans-Serif;
    text-transform: uppercase;
    color: black;
    margin: 0 2vw;
  }
`;

const StatusBar = () => {
  const statusBar = useRef();
  useEffect(() => {
    let animationTween;
    function setupAnimation() {
      let statuses = statusBar.current.childNodes;
      statuses.forEach((elem, i) => {
        gsap.set(elem, {
          x: elem.offsetWidth * i,
        });
      });
    }
    function animate() {
      let statuses = statusBar.current.childNodes;
      let container = statusBar.current;
      animationTween = gsap.to(statuses, {
        duration: 20,
        x: `+=${-container.offsetWidth - statuses[0].offsetWidth}`,
        ease: "none",
        modifiers: {
          x: gsap.utils.unitize((x) =>
            gsap.utils.wrap(
              -statuses[0].offsetWidth,
              statuses[0].offsetWidth * (statuses.length - 1),
              x
            )
          ),
        },
        onComplete: animate,
      });
    }

    setTimeout(() => {
      setupAnimation();
      animate();
    }, 1000);

    window.addEventListener("resize", () => {
      animationTween.kill();
      setupAnimation();
      animate();
    });
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/news/" } }) {
            edges {
              node {
                frontmatter {
                  news
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          allMarkdownRemark: { edges },
        } = data;
        return (
          <StatusBarContainer id="statusBar" ref={statusBar}>
            <StatusBarWrapper className="statusWrapper">
              <li>{edges[0].node.frontmatter.news}</li>
            </StatusBarWrapper>
            <StatusBarWrapper className="statusWrapper">
              <li>{edges[0].node.frontmatter.news}</li>
            </StatusBarWrapper>
            <StatusBarWrapper className="statusWrapper">
              <li>{edges[0].node.frontmatter.news}</li>
            </StatusBarWrapper>
            <StatusBarWrapper className="statusWrapper">
              <li>{edges[0].node.frontmatter.news}</li>
            </StatusBarWrapper>
          </StatusBarContainer>
        );
      }}
    />
  );
};

export default StatusBar;
