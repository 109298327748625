import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

const NavShopLine = styled.div`
  width: 28.28px;
  height: 1px;
  background-color: black;
  transform-origin: top left;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  left: 0px;

  transition: opacity 0.2s ease;
  display: block;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavShopContainer = styled.div`
  width: 150px;
  height: fit-content;
  background-color: white;
  top: 29px;
  left: 19px;
  position: absolute;
  border: 1px solid black;

  display: block;
  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  @media (max-width: 1000px) {
    top: calc(100% - 1px);
    left: calc(12% + 9px);
    z-index: 7;
  }
`;

const NavShopContainerMobile = styled.div`
  width: 150px;
  height: fit-content;
  background-color: white;
  top: 29px;
  left: 19px;
  position: absolute;
  border: 1px solid black;
  display: none;

  @media (max-width: 1000px) {
    top: calc(100% - 1px);
    left: calc(12% + 9px);
    z-index: 7;
    display: ${(props) => (props.visible ? "block" : "none")};
  }
`;

const NavShopCategory = styled(Link)`
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;
  padding: 10px 0;
  border-bottom: 1px solid black;
  color: black;
  font-size: 14px;
  text-decoration: none;
  background-color: white;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover {
    background-color: #0000ff;
    color: white;
  }
`;

const NavShopCategoryMobile = styled.button`
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid black;
  color: black;
  font-size: 14px;
  text-decoration: none;
  background-color: white;
  cursor: pointer;
  outline: none;

  &:last-child {
    border-bottom: none;
  }

  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover {
    background-color: #0000ff;
    color: white;
  }
`;

const NavShop = ({ visible, closeMobileNav }) => {
  const goToPage = (page) => {
    closeMobileNav();
    navigate(`/collection/${page}`);
  };
  return (
    <>
      <NavShopLine visible={visible}></NavShopLine>
      <NavShopContainer visible={visible}>
        <NavShopCategory to="/collection/all">All</NavShopCategory>
        <NavShopCategory to="/collection/accessories">
          ACCESSORIES
        </NavShopCategory>
        <NavShopCategory to="/collection/tops">TOPS</NavShopCategory>
        <NavShopCategory to="/collection/bottoms">BOTTOMS</NavShopCategory>
        <NavShopCategory to="/collection/outerwear">OUTERWEAR</NavShopCategory>
        <NavShopCategory to="/collection/home">HOME</NavShopCategory>
      </NavShopContainer>
      <NavShopContainerMobile visible={visible}>
        <NavShopCategoryMobile
          onClick={() => {
            goToPage("all");
          }}
        >
          All
        </NavShopCategoryMobile>
        <NavShopCategoryMobile
          onClick={() => {
            goToPage("accessories");
          }}
        >
          ACCESSORIES
        </NavShopCategoryMobile>
        <NavShopCategoryMobile
          onClick={() => {
            goToPage("tops");
          }}
        >
          TOPS
        </NavShopCategoryMobile>
        <NavShopCategoryMobile
          onClick={() => {
            goToPage("bottoms");
          }}
        >
          BOTTOMS
        </NavShopCategoryMobile>
        <NavShopCategoryMobile
          onClick={() => {
            goToPage("outerwear");
          }}
        >
          OUTERWEAR
        </NavShopCategoryMobile>
        <NavShopCategoryMobile
          onClick={() => {
            goToPage("home");
          }}
        >
          HOME
        </NavShopCategoryMobile>
      </NavShopContainerMobile>
    </>
  );
};

export default NavShop;
