import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useCheckout } from "../context/StoreContext";

const NavCartLine = styled.div`
  width: 28.28px;
  height: 1px;
  background-color: black;
  transform-origin: top left;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  bottom: 20px;

  transition: opacity 0.2s ease;
  display: block;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavCartContainer = styled.div`
  width: 350px;
  height: ${(props) =>
    props.quantity === 0 ? "40px" : props.quantity === 1 ? "180px" : "200px"};
  position: absolute;
  bottom: 0;
  left: 19px;
  background-color: white;
  border: 1px solid black;
  display: grid;
  box-sizing: border-box;

  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  grid-template-columns: 1fr;
  grid-template-rows: ${(props) =>
    props.quantity === 0 ? "40px" : "1fr 40px 40px"};

  @media (max-width: 1000px) {
    bottom: auto;
    top: calc(100% - 1px);
    left: calc(12% + 9px);
    width: calc(100% - 12% - 9px);
    box-sizing: border-box;
    z-index: 7;
  }
`;

const NavCartCheckout = styled.button`
  width: 100%;
  height: 40px;
  background: white;
  border: none;
  border-top: 1px solid black;
  display: grid;
  place-items: center;
  color: black;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;

  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover {
    background-color: #0000ff;
    color: white;
  }
`;

const NavCartBottomBar = styled.div`
  width: 100%;
  height: 39px;
  border-top: ${(props) => (props.empty ? "none" : "1px solid black")};
  display: grid;
  grid-template-columns: 100px 1fr;
`;

const NavCartBottomTotal = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-right: 1px solid black;
  box-sizing: border-box;
  color: black;
  font-size: 14px;
`;

const NavCartBottomButton = styled(Link)`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: none;
  border: none;
  color: black;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;

  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover {
    background-color: #0000ff;
    color: white;
  }
`;

const NavCartItemsWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const NavCartItemContainer = styled(Link)`
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 75px 1fr;
  text-decoration: none;

  &:last-child {
    margin: 0;
  }

  img {
    transition: opacity 0.1s ease;
  }

  p {
    transition: color 0.1s ease;
  }

  &:hover {
    img {
      opacity: 0.5;
    }

    p {
      color: #0000ff;
    }
  }
`;

const NavCartItemImg = styled(Img)`
  width: 75px;
  height: 75px;
  background-color: white;
  object-fit: cover;
`;

const NavCartItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
`;

const NavCartItemTitle = styled.p`
  width: 100%;
  margin: 0 0 10px 0;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
`;

const NavCartItemDetail = styled.p`
  margin: 0 0 5px 0;
  color: black;
  font-size: 12px;
  text-transform: uppercase;
`;

const NavCart = ({ visible, lineItems, total }) => {
  const {
    allShopifyProductVariant: { nodes: variants },
    allShopifyProduct: { nodes: products },
  } = useStaticQuery(graphql`
    query {
      allShopifyProductVariant {
        nodes {
          shopifyId
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      allShopifyProduct {
        nodes {
          handle
          variants {
            shopifyId
          }
        }
      }
    }
  `);

  const checkout = useCheckout();

  function getImageFluidForVariant(variantId) {
    const selectedVariant = variants.find((variant) => {
      return variant.shopifyId === variantId;
    });

    if (selectedVariant) {
      return selectedVariant.image.localFile.childImageSharp.fluid;
    }
    return null;
  }

  return (
    <>
      <NavCartLine visible={visible}></NavCartLine>
      <NavCartContainer visible={visible} quantity={lineItems.length}>
        {lineItems.length !== 0 ? (
          <NavCartItemsWrapper>
            {lineItems.map((item, i) => {
              return (
                <NavCartItemContainer
                  key={`item-${i}`}
                  to={`/product/${item.variant.product.handle}`}
                >
                  <NavCartItemImg
                    fluid={getImageFluidForVariant(item.variant.id)}
                  />
                  <NavCartItemContent>
                    <NavCartItemTitle>{item.title}</NavCartItemTitle>
                    <NavCartItemDetail>
                      PRICE: ${item.variant.price}
                    </NavCartItemDetail>
                    {item.variant.title.toLowerCase() !== "default title" ? (
                      <NavCartItemDetail>
                        SIZE: {item.variant.title}
                      </NavCartItemDetail>
                    ) : (
                      <></>
                    )}
                  </NavCartItemContent>
                </NavCartItemContainer>
              );
            })}
          </NavCartItemsWrapper>
        ) : (
          <></>
        )}
        <NavCartBottomBar empty={lineItems.length === 0}>
          <NavCartBottomTotal>
            {lineItems.length === 0 ? "EMPTY" : `${total}`}
          </NavCartBottomTotal>
          <NavCartBottomButton
            to={lineItems.length === 0 ? `/collection/all` : `/cart`}
          >
            {lineItems.length === 0 ? "SHOP ALL" : "SEE CART IN DETAIL"}
          </NavCartBottomButton>
        </NavCartBottomBar>
        {lineItems.length !== 0 ? (
          <NavCartCheckout onClick={checkout}>CHECKOUT</NavCartCheckout>
        ) : (
          <></>
        )}
      </NavCartContainer>
    </>
  );
};

export default NavCart;
