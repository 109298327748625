import React from "react";
import styled from "styled-components";
import StatusBar from "./StatusBar";
const NavNewsLine = styled.div`
  width: 28.28px;
  height: 1px;
  background-color: black;
  transform-origin: top left;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 10px;

  transition: opacity 0.2s ease;
  display: block;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavNewsContainer = styled.div`
  width: 350px;
  height: 40px;
  top: 30px;
  left: 19px;
  position: absolute;
  background-color: white;
  border: 1px solid black;
  overflow: hidden;
  display: block;
  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  @media (max-width: 1000px) {
    top: calc(100% - 1px);
    left: calc(12% + 9px);
    z-index: 7;
    width: calc(100% - 12% - 9px);
    box-sizing: border-box;
  }
`;

const NavNews = ({ visible }) => {
  return (
    <>
      <NavNewsLine visible={visible}></NavNewsLine>
      <NavNewsContainer visible={visible}>
        <StatusBar />
      </NavNewsContainer>
    </>
  );
};

export default NavNews;
