import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import SEO from '../components/seo';
import NavInfo from '../components/navInfo';
import NavShop from '../components/navShop';
import NavNews from '../components/navNews';
import NavCart from '../components/navCart';

import TopLeft from '../images/layout/topleft.png';
import TopRight from '../images/layout/topright.png';
import BottomLeft from '../images/layout/bottomleft.png';
import BottomRight from '../images/layout/bottomright.png';

import TopMobile from '../images/layout/topmobile.png';
import BottomLeftMobile from '../images/layout/bottomleftmobile.png';
import BottomRightMobile from '../images/layout/bottomrightmobile.png';

import { useCartItems, useCartTotals } from '../context/StoreContext';

import DesktopImg from '../images/email-landing/new-landing.jpg';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Logo from '../images/atypic.png';

// EMAIL LANDING

// const Container = styled.main`
//   display: grid;
//   grid-template-columns: 45% 1fr;
//   align-items: center;
//   grid-gap: 50px;
//   padding: 75px;
//   @media (max-width: 1000px) {
//     grid-template-columns: 1fr;
//     padding: 25px;
//   }
// `;

// const EmailForm = styled.form`
//   display: grid;
//   label {
//     font-size: 12px;
//     margin: 0 0 20px 0;
//     font-style: italic;
//     letter-spacing: 2px;
//   }
// `;

// const InputWrapper = styled.div`
//   display: grid;
//   grid-template-columns: 1fr max-content;
//   width: 400px;
//   input {
//     padding: 5px 10px;
//     letter-spacing: 2px;
//     text-transform: uppercase;
//     font-size: 10px;
//     border: 1px solid black;
//   }
//   input[type='email'] {
//     border-right: none;
//   }
//   input[type='submit'] {
//     border-left: none;
//     color: white;
//     background-color: #0000ff;
//     cursor: pointer;
//   }
//   input:focus {
//     outline: 0;
//   }
//   @media (max-width: 1000px) {
//     width: 100%;
//   }
// `;

// const Alert = styled.p`
//   font-size: 10px;
//   margin: 10px 0 0 0;
// `;

// const MobileLogo = styled.img`
//   display: none;
//   width: 50%;
//   @media (max-width: 1000px) {
//     display: block;
//   }
// `;

// const DesktopImage = styled.img`
//   width: 100%;
//   @media (max-width: 1000px) {
//     display: none;
//   }
// `;

// const MobileImage = styled.img`
//   display: none;
//   width: 100%;
//   @media (max-width: 1000px) {
//     display: block;
//   }
// `;

// ACTUAL LAYOUT

const LayoutComponent = styled.img`
  position: fixed;
  z-index: 100;
  margin: 0;
  padding: 0;
`;

const TopLeftComponent = styled(LayoutComponent)`
  width: 40px;
  height: 40px;
  top: 40px;
  left: 40px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const TopRightComponent = styled(LayoutComponent)`
  width: 40px;
  height: 250px;
  top: 40px;
  right: 40px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const TopComponentWrapper = styled.div`
  width: calc(100% - 40px);
  max-width: 600px;
  position: fixed;
  z-index: 100;
  top: 15px;
  left: 20px;
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const TopComponent = styled.img`
  width: 100%;
`;

const TopLogo = styled.button`
  background: none;
  border: none;
  color: black;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 32px;
  left: 17px;
  z-index: 100;
  cursor: pointer;
  opacity: 0;
  outline: none;

  @media (max-width: 600px) {
    top: 20px;
    left: 10px;
  }

  @media (max-width: 400px) {
    top: 10px;
    left: 0px;
  }
`;

const TopNavButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  color: black;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  margin: 0;
  top: 29%;
  left: ${(props) => `${props.left}%`};
  padding: 3px 10px;
  z-index: 100;

  @media (max-width: 600px) {
    top: 26%;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    top: 22%;
    font-size: 15px;
  }

  @media (max-width: 400px) {
    top: 17%;
    font-size: 14px;
  }
`;

const TopNavLine = styled.div`
  width: 14.14px;
  height: 1px;
  background-color: black;
  transform-origin: top left;
  transform: rotate(45deg);
  position: absolute;
  left: 12%;
  bottom: 10px;
  z-index: 100;
  transition: opacity 0.2s ease;
  display: block;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

const TopBGDiv = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const BottomRightComponent = styled(LayoutComponent)`
  width: 40px;
  height: 100px;
  bottom: 40px;
  right: 40px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const BottomRightComponentMobile = styled(LayoutComponent)`
  width: 30px;
  bottom: 20px;
  right: 20px;
  height: 75px;
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const BottomLeftComponentMobile = styled(LayoutComponent)`
  width: 30px;
  bottom: 20px;
  left: 20px;
  height: 30px;
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const BottomLeftComponentWrapper = styled(Link)`
  position: fixed;
  z-index: 98;
  width: 275px;
  height: 80px;
  bottom: 40px;
  left: 40px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const BottomLeftComponent = styled.img`
  width: 275px;
  height: 80px;
`;

const BottomLeftLetter = styled.span`
  position: absolute;
  font-weight: 300;
  font-size: 24px;
  color: black;
  margin: 0;
  padding: 0;
  top: 17px;
  left: ${(props) => `${props.left}px`};
`;

const Navigation = styled.nav`
  position: fixed;
  left: 40px;
  bottom: 160px;
  z-index: 99;
  height: fit-content;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: black;
  font-size: 16px;
  padding: 25px 20px 25px 0px;
  margin: 0;
  width: 70px;
  text-align: left;
  cursor: pointer;
  position: relative;
  outline: none;

  &:last-child {
    /* margin: 0; */
    padding: 25px 20px 0 0;
  }
`;

const NavShopWrapper = styled.div`
  position: fixed;
  width: 150px;
  height: 240px;
  bottom: 150px;
  left: 100px;
  z-index: 98;
  /* background: rgba(0, 0, 255, 0.2); */
  box-sizing: border-box;
  display: block;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavInfoWrapper = styled(NavShopWrapper)`
  width: 350px;
  height: 250px;
  bottom: 290px;
  /* background: rgba(255, 0, 0, 0.2); */
`;

const NavNewsWrapper = styled(NavShopWrapper)`
  width: 350px;
  height: 70px;
  bottom: 180px;
  /* background: rgba(0, 255, 0, 0.2); */
`;

const NavCartWrapper = styled(NavShopWrapper)`
  width: 350px;
  height: ${(props) =>
    props.quantity === 0 ? '40px' : props.quantity === 1 ? '180px' : '200px'};
  bottom: 150px;
  /* background: rgba(255, 255, 0, 0.4); */
`;

const Layout = ({ children, location }) => {
  // EMAIL LANDING

  // const [result, setResult] = useState();
  // const [email, setEmail] = useState('');
  // const [alert, setAlert] = useState('');

  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const result = await addToMailchimp(email);
  //   setResult(result);
  //   setAlert('Your email has been added to the mailing list.');
  // };

  // ACTUAL LAYOUT
  const [openNav, setOpenNav] = useState('');
  const [onNavButton, setOnNavButton] = useState(false);
  const [onNav, setOnNav] = useState(false);

  const [openMobileNav, setOpenMobileNav] = useState('');
  const openMobileNavShop = () => {
    setOpenMobileNav('shop');
  };
  const openMobileNavInfo = () => {
    setOpenMobileNav('info');
  };
  const openMobileNavNews = () => {
    setOpenMobileNav('news');
  };
  const openMobileNavCart = () => {
    setOpenMobileNav('cart');
  };

  const closeMobileNav = () => {
    setOpenMobileNav('');
  };

  const navHoverIn = (name) => {
    setOpenNav(name);
    setOnNavButton(true);
  };

  const navHoverOut = (name) => {
    setOnNavButton(false);
  };

  const onActualNav = () => {
    setOnNav(true);
  };

  const offActualNav = () => {
    setOnNav(false);
  };

  useEffect(() => {
    if (!onNav && !onNavButton) {
      setOpenNav('');
    }
  }, [onNav, onNavButton]);

  const goHomeMobile = () => {
    closeMobileNav();
    navigate('/');
  };

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const handleResize = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const lineItems = useCartItems().lineItems;
  const { total } = useCartTotals();

  return (
    <>
      <SEO />
      <TopBGDiv onClick={closeMobileNav} active={openMobileNav !== ""} />
      <TopLeftComponent src={TopLeft} />
      <TopRightComponent src={TopRight} />
      <BottomRightComponent src={BottomRight} />
      <BottomLeftComponentWrapper to="/">
        <BottomLeftLetter left={90}>A</BottomLeftLetter>
        <BottomLeftLetter left={121}>T</BottomLeftLetter>
        <BottomLeftLetter left={155}>Y</BottomLeftLetter>
        <BottomLeftLetter left={187}>P</BottomLeftLetter>
        <BottomLeftLetter left={221}>I</BottomLeftLetter>
        <BottomLeftLetter left={250}>C</BottomLeftLetter>
        <BottomLeftComponent src={BottomLeft} />
      </BottomLeftComponentWrapper>

      <TopComponentWrapper>
        <TopLogo onClick={goHomeMobile}></TopLogo>
        <TopNavButton onClick={openMobileNavShop} left={16}>
          SHOP
        </TopNavButton>
        <TopNavButton onClick={openMobileNavInfo} left={37}>
          INFO
        </TopNavButton>
        <TopNavButton onClick={openMobileNavNews} left={59}>
          NEWS
        </TopNavButton>
        <TopNavButton onClick={openMobileNavCart} left={82}>
          CART
        </TopNavButton>
        <TopComponent src={TopMobile} />
        <TopNavLine visible={openMobileNav !== ""}></TopNavLine>
        <NavShop
          visible={openMobileNav === "shop"}
          closeMobileNav={closeMobileNav}
        />
        <NavInfo visible={openMobileNav === "info"} />
        <NavNews visible={openMobileNav === "news"} />
        <NavCart
          visible={openMobileNav === "cart"}
          lineItems={lineItems}
          total={total}
        />
      </TopComponentWrapper>

      <BottomLeftComponentMobile src={BottomLeftMobile} />
      <BottomRightComponentMobile src={BottomRightMobile} />

      <>
        <Navigation>
          <NavButton
            onMouseEnter={() => {
              navHoverIn("shop");
            }}
            onMouseLeave={navHoverOut}
          >
            SHOP
          </NavButton>
          <NavButton
            onMouseEnter={() => {
              navHoverIn("info");
            }}
            onMouseLeave={navHoverOut}
          >
            INFO
          </NavButton>
          <NavButton
            onMouseEnter={() => {
              navHoverIn("news");
            }}
            onMouseLeave={navHoverOut}
          >
            NEWS
          </NavButton>
          <NavButton
            onMouseEnter={() => {
              navHoverIn("cart");
            }}
            onMouseLeave={navHoverOut}
          >
            CART
          </NavButton>
        </Navigation>
        <NavShopWrapper
          onMouseEnter={onActualNav}
          onMouseLeave={offActualNav}
          visible={openNav === "shop"}
        >
          <NavShop visible={openNav === "shop"}></NavShop>
        </NavShopWrapper>
        <NavInfoWrapper
          onMouseEnter={onActualNav}
          onMouseLeave={offActualNav}
          visible={openNav === "info"}
        >
          <NavInfo visible={openNav === "info"} />
        </NavInfoWrapper>
        <NavNewsWrapper
          onMouseEnter={onActualNav}
          onMouseLeave={offActualNav}
          visible={openNav === "news"}
        >
          <NavNews visible={openNav === "news"} />
        </NavNewsWrapper>
        <NavCartWrapper
          onMouseEnter={onActualNav}
          onMouseLeave={offActualNav}
          visible={openNav === "cart"}
          quantity={lineItems.length}
        >
          <NavCart
            visible={openNav === "cart"}
            lineItems={lineItems}
            total={total}
          />
        </NavCartWrapper>
      </>

      {children}

      {/* <Container>
        <MobileLogo src={Logo} alt="Atypic logo" />
        <EmailForm onSubmit={handleSubmit}>
          <label>Subscribe for exclusive content and early access</label>
          <InputWrapper>
            <input
              type="email"
              placeholder="Email:"
              value={email}
              onChange={handleEmail}
            />
            <input type="submit" value="Enter" />
          </InputWrapper>
          <Alert>{alert}</Alert>
        </EmailForm>
        <DesktopImage src={DesktopImg} alt="clothing from atypic" />
        <MobileImage src={DesktopImg} alt="clothing from atypic" />
      </Container> */}
    </>
  );
};

export default Layout;
